import styles from "./Contato.module.scss";
import Tilt from "react-parallax-tilt";
import logo from "assets/img/logo.png";
import classNames from "classnames";
import { useState } from "react";
import emailjs from "@emailjs/browser";
import Swal from "sweetalert2";

import gsap from "gsap";
import { useGSAP } from "@gsap/react";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(useGSAP, ScrollTrigger);

export default function Contato() {
  useGSAP(() => {
    gsap.from(`.${styles["contato__titulo"]}`, {
      opacity: 0,
      y: 50,
      duration: 1,
      delay: 0.5,
      scrollTrigger: {
        trigger: `.${styles["contato__apresentacao"]}`,
        start: "top 80%",
        end: "bottom 60%",
        toggleActions: "play none none none",
      },
    });

    gsap.from(`.${styles["contato__formulario"]}`, {
      opacity: 0,
      y: 50,
      duration: 1,
      delay: 1,
      scrollTrigger: {
        trigger: `.${styles["contato__apresentacao"]}`,
        start: "top 80%",
        end: "bottom 60%",
        toggleActions: "play none none none",
      },
    });

    gsap.from(`.${styles["contato__imagem"]}`, {
      opacity: 0,
      y: 50,
      duration: 1,
      delay: 1.5,
      scrollTrigger: {
        trigger: `.${styles["contato__apresentacao--imagem"]}`,
        start: "top 80%",
        end: "bottom 60%",
        toggleActions: "play none none none",
      },
    });
  });
  const [nome, setNome] = useState("");
  const [email, setEmail] = useState("");
  const [assunto, setAssunto] = useState("");
  const [mensagem, setMensagem] = useState("");

  const enviarContato = (event: any) => {
    event.preventDefault();
    const templateParams = {
      nome: `${nome}`,
      email: `${email}`,
      assunto: `${assunto}`,
      mensagem: `${mensagem}`,
    };

    emailjs
      .send(
        "service_e233uer",
        "template_f17t17g",
        templateParams,
        "ErGaHW2if7hSXypcW"
      )
      .then((response) => {
        Swal.fire({
          title: "Sucesso!",
          text: "Contato realizado com sucesso!",
          icon: "success",
          confirmButtonColor: "#1A75FF",
        });
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Erro ao tentar contatar!",
          confirmButtonColor: "#1A75FF",
        });
      });
  };
  return (
    <>
      <section className={styles["contato"]}>
        <div className={styles["contato__apresentacao"]}>
          <h2 className={styles["contato__titulo"]}>
            Alguma dúvida? Envie sua mensagem
          </h2>
          <form
            onSubmit={enviarContato}
            className={styles["contato__formulario"]}
          >
            <input
              onChange={(event) => {
                setNome(event.target.value);
              }}
              required
              placeholder="Nome"
              className={classNames({
                [styles["contato__input"]]: true,
                [styles["contato__input--nome"]]: true,
              })}
              type="text"
            />
            <input
              onChange={(event) => {
                setEmail(event.target.value);
              }}
              required
              placeholder="Email"
              className={classNames({
                [styles["contato__input"]]: true,
                [styles["contato__input--email"]]: true,
              })}
              type="email"
            />
            <input
              onChange={(event) => {
                setAssunto(event.target.value);
              }}
              required
              placeholder="Assunto"
              className={classNames({
                [styles["contato__input"]]: true,
                [styles["contato__input--assunto"]]: true,
              })}
              type="text"
            />
            <textarea
              onChange={(event) => {
                setMensagem(event.target.value);
              }}
              required
              placeholder="Mensagem"
              className={classNames({
                [styles["contato__input"]]: true,
                [styles["contato__input--mensagem"]]: true,
              })}
              name=""
              id=""
              cols={30}
              rows={10}
            ></textarea>
            <button className={styles["contato__botao"]}>Enviar</button>
          </form>
        </div>
        <div className={styles["contato__apresentacao--imagem"]}>
          <Tilt>
            <img src={logo} alt="" className={styles["contato__imagem"]} />
          </Tilt>
        </div>
      </section>
      <section>
        <iframe
          title="mapa"
          className={styles["contato__mapa"]}
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3563.1419220276466!2d-53.5188327!3d-26.739846800000002!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94fa5c62c0e82255%3A0x8c351f0dfa7661ea!2sR.%20Florian%C3%B3polis%2C%201727%20-%20S%C3%A3o%20Jorge%2C%20S%C3%A3o%20Miguel%20do%20Oeste%20-%20SC%2C%2089900-000!5e0!3m2!1spt-PT!2sbr!4v1712240519753!5m2!1spt-PT!2sbr"
          height="450"
          style={{ border: 0 }}
          loading="lazy"
        ></iframe>
      </section>
    </>
  );
}
