import { useRef } from "react";

import gsap from "gsap"; // <-- import GSAP
import { useGSAP } from "@gsap/react"; // <-- import the hook from our React package

gsap.registerPlugin(useGSAP);

export default function App() {
  const container = useRef(null);

  useGSAP(() => {
    // gsap code here...
    gsap.to(".box", {rotation: 180}); // <-- automatically reverted

  }, { scope: container }) // <-- scope for selector text (optional)

 return (
   <div style={{minHeight: "100vh"}} ref={container} className="app">
     <div style={{position: "absolute", top: "10rem"}} className="box">Hello</div>
   </div>
 );
}