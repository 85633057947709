import fundoInicio from "../../assets/img/fundo-inicio.png";
import { ReactComponent as Check } from "../../assets/svg/check.svg";
import { ReactComponent as ArrowDown } from "../../assets/svg/arrow_down.svg";
import { useEffect, useRef, useState } from "react";
import gsap from "gsap";
import { useGSAP } from "@gsap/react";
import { ScrollTrigger } from "gsap/ScrollTrigger";

import styles from "./Inicio.module.scss";
import Type from "components/TypeWritter";
import ebrigada from "../../assets/img/ebrigada.png";
import randon from "../../assets/img/randon.png";
import johndeere from "../../assets/img/johndeere.png";
import albert from "../../assets/img/albert.png";
import { ReactComponent as CheckPubli } from "../../assets/svg/check_publi.svg";
import { ReactComponent as CellPubli } from "../../assets/svg/cell_publi.svg";
import { ReactComponent as MarketPubli } from "../../assets/svg/market_publi.svg";
import rec1 from "../../assets/img/rec-1.png";
import rec2 from "../../assets/img/rec-2.png";
import Tilt from "react-parallax-tilt";
import { Link } from "react-router-dom";
import { useQuery } from "graphql-hooks";

gsap.registerPlugin(useGSAP, ScrollTrigger);

export default function Inicio() {
  const [depoimentosLoaded, setDepoimentosLoaded] = useState(false);

  const GET_DEPOIMENTOS = `
    query {
      allDepoimentos(first: 3) {
        texto
        autor {
          url
        }
        nome
        profissao
      }
    }
  `;

  const { loading, error, data } = useQuery(GET_DEPOIMENTOS);

  useEffect(() => {
    if (data) {
      setDepoimentosLoaded(true);
    }
  }, [data]);

  useEffect(() => {
    if (depoimentosLoaded) {
      gsap.from(`.${styles["depoimentos__depoimento"]}`, {
        opacity: 0,
        y: 50,
        duration: 1,
        delay: 0.5,
        stagger: 0.5,
        scrollTrigger: {
          trigger: `.${styles["depoimentos"]}`,
          start: "top 80%",
          end: "bottom 60%",
          toggleActions: "play none none none",
        },
      });
    }
  }, [depoimentosLoaded]);
  useGSAP(() => {
    gsap.from(`.${styles["inicio__titulo--div"]}`, {
      opacity: 0,
      y: 50,
      duration: 1,
      delay: 0.5,
    });

    gsap.from(`.${styles["inicio__paragrafo"]}`, {
      opacity: 0,
      y: 50,
      duration: 1,
      delay: 1,
    });

    gsap.from(`.botao--link`, {
      opacity: 0,
      x: -50,
      duration: 1,
      delay: 1.5,
    });

    gsap.from(`.${styles["inicio__checks"]}`, {
      opacity: 0,
      x: -50,
      duration: 1,
      delay: 1.5,
      stagger: 0.5,
    });

    gsap.from(`.${styles["empresas__empresa"]}`, {
      opacity: 0,
      y: 50,
      duration: 1,
      delay: 0.5,
      stagger: 0.5,
      scrollTrigger: {
        trigger: `.${styles["empresas"]}`, // O elemento que dispara a animação
        start: "top 80%", // Inicia a animação quando o topo do elemento atinge 80% do viewport
        end: "bottom 60%", // Termina a animação quando o final do elemento atinge 60% do viewport
        toggleActions: "play none none none", // Ação de animação
      },
    });
    gsap.from(`.${styles['projetos__titulo']}`, {
      opacity: 0,
      x: 50,
      duration: 1,
      scrollTrigger: {
        trigger: `.${styles['projetos__titulo']}`,
        start: "top 80%",  // Inicia a animação quando o topo do elemento atinge 80% da altura da viewport
        toggleActions: "play none none none",  // Reverte a animação ao sair da viewport
      },
    });
    gsap.from(`.${styles['projetos__paragrafo']}`, {
      opacity: 0,
      x: -50,
      duration: 1,
      scrollTrigger: {
        trigger: `.${styles['projetos__paragrafo']}`,
        start: "top 80%",  // Inicia a animação quando o topo do elemento atinge 80% da altura da viewport
        toggleActions: "play none none none",  // Reverte a animação ao sair da viewport
      },
    });

    gsap.from(`.${styles["publicidade__lista"]}`, {
      opacity: 0,
      y: 50,
      duration: 1,
      delay: 0.5,
      stagger: 0.5,
      scrollTrigger: {
        trigger: `.${styles["publicidade"]}`,
        start: "top 80%",
        end: "bottom 60%",
        toggleActions: "play none none none",
      },
    });

    gsap.from(`.${styles["publicidade__fotos"]} img`, {
      opacity: 0,
      scale: 0.5,
      duration: 1,
      delay: 0.5,
      stagger: 0.5,
      scrollTrigger: {
        trigger: `.${styles["publicidade"]}`,
        start: "top 80%",
        end: "bottom 60%",
        toggleActions: "play none none none",
      },
    });

  });



  return (
    <>
      <section className={styles["inicio"]}>
        <div className={styles["inicio__apresentacao"]}>
          <h2 className={styles["inicio__titulo"]}>
            <div className={styles["inicio__titulo--div"]}>
              <Type
                strings={[
                  "A solução mais fácil para seu negócio.",
                  "Resultados eficientes e econômicos.",
                  "Otimize seu tempo e dinheiro conosco.",
                  "Soluções completas para o seu negócio.",
                  "Tudo o que precisa em um só lugar.",
                ]}
              />
            </div>
          </h2>
          <p className={styles["inicio__paragrafo"]}>
            Ferramentas poderosas de gerenciamento de projetos para empresas de
            todos os tamanhos.
          </p>
          <Link to={"/casosdesucesso"} className="botao--link">
            <button className={styles["inicio__botao"]}>Veja mais</button>
          </Link>
          <ul style={{ display: "flex", flexDirection: "row" }}>
            <li className={styles["inicio__checks"]}>
              <Check></Check> <p>Empresa sólida no mercado</p>
            </li>
            <li className={styles["inicio__checks"]}>
              <Check></Check> <p>Soluções multi-plataforma </p>
            </li>
          </ul>
        </div>
        <div className={styles["inicio__apresentacao--imagem"]}>
          <Tilt>
            <img
              src={fundoInicio}
              alt=""
              className={styles["inicio__imagem"]}
            />
          </Tilt>
        </div>
      </section>
      <ArrowDown className={styles["inicio__arrowdown"]}></ArrowDown>
      {/* <section className={styles["empresas"]}>
        <h3 className={styles["empresas__titulo"]}>Empresas parceiras</h3>
        <p className={styles["empresas__paragrafo"]}>
          Algumas das empresas que confiam em nosso trabalho
        </p>
        <ul className={styles["empresas__lista"]}>
          <li className={styles["empresas__empresa"]}>
            {" "}
            <img src={ebrigada} alt="" />{" "}
          </li>
          <li className={styles["empresas__empresa"]}>
            {" "}
            <img src={randon} alt="" />{" "}
          </li>
          <li className={styles["empresas__empresa"]}>
            {" "}
            <img src={johndeere} alt="" />{" "}
          </li>
          <li className={styles["empresas__empresa"]}>
            {" "}
            <img src={albert} alt="" />{" "}
          </li>
        </ul>
      </section> */}
      <section className={styles["projetos"]}>
        <h3 className={styles["projetos__titulo"]}>+200</h3>
        <p className={styles["projetos__paragrafo"]}>
        Com orgulho, já executamos mais de 200 projetos abrangendo uma ampla variedade de soluções, incluindo sistemas, aplicativos e sites.        </p>
       
      </section>
      <section className={styles["publicidade"]}>
        <div className={styles["publicidade__info"]}>
          <ul className={styles["publicidade__lista"]}>
            <CheckPubli></CheckPubli>
            <h4 className={styles["publicidade__subtitulo"]}>
              Projetos estáveis
            </h4>
            <p className={styles["publicidade__paragrafo"]}>
              Nossos sistemas são caracterizados por sua estabilidade e
              confiabilidade excepcionais, garantindo uma experiência
              consistente para os usuários.
            </p>
          </ul>

          <ul className={styles["publicidade__lista"]}>
            <CellPubli></CellPubli>
            <h4 className={styles["publicidade__subtitulo"]}>Responsividade</h4>
            <p className={styles["publicidade__paragrafo"]}>
              Projetos com telas totalmente responsivas para tablets e
              dispositivos móveis.
            </p>
          </ul>

          <ul className={styles["publicidade__lista"]}>
            <MarketPubli></MarketPubli>
            <h4 className={styles["publicidade__subtitulo"]}>
              Marketing Digital
            </h4>
            <p className={styles["publicidade__paragrafo"]}>
              Divulgamos seu produto e sua marca em todas as mídias digitais.
            </p>
          </ul>
        </div>
        <div className={styles["publicidade__fotos"]}>
          <img src={rec1} alt="" />
          <img src={rec2} alt="" />
        </div>
      </section>

      <section className={styles["depoimentos"]}>
        <h3 className={styles["depoimentos__titulo"]}>
          O que nossos clientes dizem?
        </h3>
        <p className={styles["depoimentos__subtitulo"]}>
          Alguns dos depoimentos de nossos clientes
        </p>
        <ul className={styles["depoimentos__lista"]}>
          {data?.allDepoimentos.map((depoimento: any, index: any) => {
            return (
              <li key={index} className={styles["depoimentos__depoimento"]}>
                <p className={styles["depoimentos__paragrafo"]}>
                  {depoimento.texto}
                </p>
                <div className={styles["depoimentos__resp"]}>
                  <img
                    className={styles["depoimentos__foto"]}
                    src={depoimento.autor.url}
                    alt=""
                  />
                  <div className={styles["depoimentos__in"]}>
                    <h4 className={styles["depoimentos__nome"]}>
                      {depoimento.nome}
                    </h4>
                    <p>{depoimento.profissao}</p>
                  </div>
                </div>
              </li>
            );
          })}
        </ul>
      </section>
    </>
  );
}
