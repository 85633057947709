import { BrowserRouter, Route, Routes } from "react-router-dom";
import ScrollToTop from "./components/ScrollToTop";
import Cabecalho from "./components/Cabecalho";
import Rodape from "./components/Rodape";
import Inicio from "./pages/Inicio";
import Newsletter from "components/Newsletter";
import Servicos from "pages/Servicos";
import Empresa from "pages/Empresa";
import CasosDeSucesso from "pages/CasosDeSucesso";
import Contato from "pages/Contato";
import App from "pages/test";
import { ClientContext, GraphQLClient } from "graphql-hooks";

export const client = new GraphQLClient({
  url: "https://graphql.datocms.com/",
  headers: {
    Authorization: `Bearer 0e9192a7cd68b302d75f64d985a5c4`,
  },
});

export default function AppRouter() {
  return (
    <ClientContext.Provider value={client}>
      <BrowserRouter>
        <ScrollToTop></ScrollToTop>
        <Cabecalho></Cabecalho>
        <Routes>
          <Route path="/" element={<Inicio></Inicio>}></Route>
          <Route path="/servicos" element={<Servicos></Servicos>}></Route>
          <Route path="/empresa" element={<Empresa></Empresa>}></Route>
          <Route
            path="/casosdesucesso"
            element={<CasosDeSucesso></CasosDeSucesso>}
          ></Route>
          <Route path="/contato" element={<Contato></Contato>}></Route>
          <Route path="/test" element={<App></App>}></Route>
        </Routes>
        <Newsletter></Newsletter>
        <Rodape></Rodape>
      </BrowserRouter>
    </ClientContext.Provider>
  );
}
