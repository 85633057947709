import Type from "components/TypeWritter";
import styles from "./Empresa.module.scss";
import Tilt from "react-parallax-tilt";
import imagemFundoEmpresa from "assets/img/imagem-fundo-empresa.png";


import gsap from "gsap";
import { useGSAP } from "@gsap/react";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(useGSAP, ScrollTrigger);


export default function Empresa() {
  useGSAP(() => {
    gsap.from(`.${styles['empresa__titulo--div']}`, {
      opacity: 0,
      y: 50,
      duration: 1,
      delay: 0.5,
      scrollTrigger: {
        trigger: `.${styles['empresa']}`,
        start: "top 80%",
        end: "bottom 60%",
        toggleActions: "play none none none"
      }
    });

    gsap.from(`.${styles['empresa__paragrafo']}`, {
      opacity: 0,
      y: 50,
      duration: 1,
      delay: 1,
      scrollTrigger: {
        trigger: `.${styles['empresa']}`,
        start: "top 80%",
        end: "bottom 60%",
        toggleActions: "play none none none"
      }
    });

    gsap.from(`.${styles['sobre__titulo']}, .${styles['sobre__paragrafo']}`, {
      opacity: 0,
      y: 50,
      duration: 1,
      delay: 0.5,
      stagger: 0.3,
      scrollTrigger: {
        trigger: `.${styles['sobre']}`,
        start: "top 80%",
        end: "bottom 60%",
        toggleActions: "play none none none"
      }
    });

    gsap.from(`.${styles['objetivos__item']}`, {
      opacity: 0,
      y: 50,
      duration: 1,
      delay: 0.5,
      stagger: 0.3,
      scrollTrigger: {
        trigger: `.${styles['objetivos']}`,
        start: "top 80%",
        end: "bottom 60%",
        toggleActions: "play none none none"
      }
    });
  })
  return (
    <>
      <section className={styles["empresa"]}>
        <div className={styles["empresa__apresentacao"]}>
          <h2 className={styles["empresa__titulo"]}>
            <div className={styles["empresa__titulo--div"]}>
              <Type
                strings={[
                  "Alcance Tecnologia: Sua parceira para o sucesso.",
                  "Uma parceria confiável para seu crescimento.",
                  "Sua equipe para conquistar grandes feitos."
                ]}
              />
            </div>
          </h2>
          <p className={styles["empresa__paragrafo"]}>
            A Alcance Tecnologia é uma empresa especializada em fornecer
            serviços de alta qualidade para empresas de todos os portes.
          </p>
        </div>
        <div className={styles["empresa__apresentacao--imagem"]}>
          <Tilt>
            <img
              src={imagemFundoEmpresa}
              alt=""
              className={styles["empresa__imagem"]}
            />
          </Tilt>
        </div>
      </section>
      <section className={styles["sobre"]}>
        <h3 className={styles["sobre__titulo"]}>Sobre</h3>
        <p className={styles["sobre__paragrafo"]}>
          A Alcance Tecnologia é uma empresa que atua na área de Tecnologia da
          Informação e tem o propósito de oferecer serviços de qualidade a
          clientes que estejam dispostos a firmar parcerias comerciais sólidas e
          prósperas.
        </p>
        <p className={styles["sobre__paragrafo"]}>
          Focada em planejamento estratégico e excelência criativa no
          desenvolvimento de ferramentas digitais, a Alcance Tecnologia propõe a
          seus clientes a obtenção de melhores resultados através do uso e da
          otimização de tecnologias voltadas para o marketing corporativo e
          gerenciamento de processos internos.
        </p>
      </section>
      <section className={styles["objetivos"]}>
        <ul className={styles["objetivos__lista"]}>
          <li className={styles["objetivos__item"]}>
            <h5 className={styles["objetivos__subtitulo"]}>Missão</h5>
            <p className={styles["objetivos__paragrafo"]}>
              Promover serviços com excelência e inovação na área das
              tecnologias de informação e comunicação, melhorando as
              oportunidades comerciais de nossos clientes.
            </p>
          </li>
          <li className={styles["objetivos__item"]}>
            <h5 className={styles["objetivos__subtitulo"]}>Visão</h5>
            <p className={styles["objetivos__paragrafo"]}>
              Consolidar-se como empresa de excelência em desenvolvimento de
              softwares para os segmentos agropecuário, cooperativista e
              corporativo.
            </p>
          </li>
          <li className={styles["objetivos__item"]}>
            <h5 className={styles["objetivos__subtitulo"]}>Valores</h5>
            <p className={styles["objetivos__paragrafo"]}>
              Respeitar seus parceiros e colaboradores promovendo justas
              relações comerciais e humanas, em prol do desenvolvimento ético de
              tecnologias de informação para o setores agropecuário,
              cooperativista e corporativo.
            </p>
          </li>
        </ul>
      </section>
    </>
  );
}
