import { useEffect, useState } from "react";
import styles from "./Cabecalho.module.scss";
import { ReactComponent as MenuIcon } from "../../assets/svg/menu_icon.svg";
import { ReactComponent as CloseIcon } from "../../assets/svg/close_icon.svg";
import { Link, useLocation, useNavigate } from "react-router-dom";
import classNames from "classnames";
import logo from "../../assets/img/logo.png";
export default function Cabecalho() {
  const [menuAparecer, setMenuAparecer] = useState(false);
  const [selecionado, setSelecionado] = useState("");
  const navigate = useNavigate();
  const href = useLocation().pathname;
  useEffect(() => {
    switch (href) {
      case "/":
        setSelecionado("Início");
        break;
      case "/servicos":
        setSelecionado("Serviços");
        break;
      case "/empresa":
        setSelecionado("Empresa");
        break;
      case "/casosdesucesso":
        setSelecionado("Casos de sucesso");
        break;
    }
  }, [href]);
  return (
    <header className={styles["cabecalho"]}>
      <a href="/">
        <img
          className={styles["cabecalho__logo"]}
          src={logo}
          alt="Logo da Alcance"
        />
      </a>
      <MenuIcon
        className={styles["cabecalho__menuicon"]}
        onClick={() => {
          setMenuAparecer(true);
        }}
      ></MenuIcon>
      <div
        className={classNames({
          [styles["cabecalho__navegacao--div"]]: true,
          [styles["cabecalho__navegacao--div--aparecer"]]: menuAparecer
            ? true
            : false,
        })}
        onClick={() => {
          setMenuAparecer(false);
        }}
      ></div>
      <nav
        className={classNames({
          [styles["cabecalho__navegacao"]]: true,
          [styles["cabecalho__navegacao--aparecer"]]: menuAparecer
            ? true
            : false,
        })}
      >
        <CloseIcon
          onClick={() => {
            setMenuAparecer(false);
          }}
          className={styles["cabecalho__closeicon"]}
        ></CloseIcon>
        <ul className={styles["cabecalho__lista"]}>
          <Link to={"/"}>
            <li
              className={classNames({
                [styles["cabecalho__link"]]: true,
                [styles["cabecalho__link--selecionado"]]:
                  selecionado === "Início" ? true : false,
              })}
              onClick={() => {
                setSelecionado("Início");
                setMenuAparecer(false);
              }}
            >
              Início
            </li>
          </Link>
          <Link to={"/servicos"}>
            <li
              className={classNames({
                [styles["cabecalho__link"]]: true,
                [styles["cabecalho__link--selecionado"]]:
                  selecionado === "Serviços" ? true : false,
              })}
              onClick={() => {
                setSelecionado("Serviços");
                setMenuAparecer(false);
              }}
            >
              Serviços
            </li>
          </Link>
          <Link to={"/empresa"}>
            <li
              className={classNames({
                [styles["cabecalho__link"]]: true,
                [styles["cabecalho__link--selecionado"]]:
                  selecionado === "Empresa" ? true : false,
              })}
              onClick={() => {
                setSelecionado("Empresa");
                setMenuAparecer(false);
              }}
            >
              Empresa
            </li>
          </Link>
          <Link to={"/casosdesucesso"}>
            <li
              className={classNames({
                [styles["cabecalho__link"]]: true,
                [styles["cabecalho__link--selecionado"]]:
                  selecionado === "Casos de sucesso" ? true : false,
              })}
              onClick={() => {
                setSelecionado("Casos de sucesso");
                setMenuAparecer(false);
              }}
            >
              Casos de sucesso
            </li>
          </Link>
        </ul>
        <div
          className={classNames({
            [styles["cabecalho__contato--div"]]: true,
            [styles["cabecalho__contato--div--mob"]]: true,
          })}
        >
          <Link
            to={"/contato"}
            onClick={() => {
              setSelecionado("");
              setMenuAparecer(false);
            }}
          >
            <button className={styles["cabecalho__contato"]}>Contato</button>
          </Link>
        </div>
      </nav>
      <div
        className={classNames({
          [styles["cabecalho__contato--div"]]: true,
          [styles["cabecalho__contato--div--desk"]]: true,
        })}
      >
        <button
          onClick={() => {
            navigate("/contato");
            setSelecionado("");
          }}
          className={styles["cabecalho__contato"]}
        >
          Contato
        </button>
      </div>
    </header>
  );
}
