import Type from "components/TypeWritter";
import styles from "./CasosDeSucesso.module.scss";
import Tilt from "react-parallax-tilt";
import imagemFundoCasosDeSucesso from "assets/img/imagem-fundo-casosdesucesso.png";

import ReactMarkdown from "react-markdown";

import gsap from "gsap";
import { useGSAP } from "@gsap/react";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useQuery } from "graphql-hooks";
import classNames from "classnames";
import { useEffect, useState } from "react";

gsap.registerPlugin(useGSAP, ScrollTrigger);

export default function CasosDeSucesso() {
  const [casosLoaded, setCasosLoaded] = useState(false);

  const GET_CASOS = `
  query {
    allEmpresas {
      logo {
        url
      }
       nome
      apresentacao
      link
      alinhamentoesquerda
    }
  }`;
  const { loading, error, data } = useQuery(GET_CASOS);

  useGSAP(() => {
    gsap.from(`.${styles["casosdesucesso__titulo--div"]}`, {
      opacity: 0,
      y: 50,
      duration: 1,
      delay: 0.5,
      scrollTrigger: {
        trigger: `.${styles["casosdesucesso"]}`,
        start: "top 80%",
        end: "bottom 60%",
        toggleActions: "play none none none",
      },
    });

    gsap.from(`.${styles["casosdesucesso__paragrafo"]}`, {
      opacity: 0,
      y: 50,
      duration: 1,
      delay: 1,
      scrollTrigger: {
        trigger: `.${styles["casosdesucesso"]}`,
        start: "top 80%",
        end: "bottom 60%",
        toggleActions: "play none none none",
      },
    });
  });

  useEffect(() => {
    if (data) {
      setCasosLoaded(true);
    }
  }, [data]);

  useEffect(() => {
    if (casosLoaded) {
      const elementosEsquerda = document.querySelectorAll(
        `.${styles["esquerda"]}`
      );

      elementosEsquerda.forEach((elemento, index) => {
        gsap.from(elemento, {
          opacity: 0,
          y: 50,
          duration: 1,
          delay: 0.5, // Delay aumenta com base no índice
          scrollTrigger: {
            trigger: elemento,
            start: "top 80%",
            end: "bottom 60%",
            toggleActions: "play none none none",
          },
        });
      });
    }
  }, [casosLoaded]);

  return (
    <>
      <section className={styles["casosdesucesso"]}>
        <div className={styles["casosdesucesso__apresentacao"]}>
          <h2 className={styles["casosdesucesso__titulo"]}>
            <div className={styles["casosdesucesso__titulo--div"]}>
              <Type
                strings={[
                  "Histórias inspiradoras de sucesso.",
                  "Relatos de vitórias e realizações.",
                  "Experiências de crescimento e desenvolvimento.",
                ]}
              />
            </div>
          </h2>
          <p className={styles["casosdesucesso__paragrafo"]}>
            Nesta página, você encontrará uma seleção de casos de sucesso que
            demonstram como ajudamos nossos clientes a superar desafios e
            alcançar seus objetivos.
          </p>
        </div>
        <div className={styles["casosdesucesso__apresentacao--imagem"]}>
          <Tilt>
            <img
              src={imagemFundoCasosDeSucesso}
              alt=""
              className={styles["casosdesucesso__imagem"]}
            />
          </Tilt>
        </div>
      </section>
      <section className={styles["casos"]}>
        {data?.allEmpresas.map((caso: any, index: any) => {
          return (
            <div
              key={index}
              className={classNames({
                [styles["esquerda"]]: true,
                [styles["direita"]]: caso.alinhamentoesquerda ? false : true,
              })}
            >
              <div className={styles["esquerda__div"]}>
                <img
                  className={styles["esquerda__imagem"]}
                  src={caso.logo.url}
                  alt="eBrigada"
                />
              </div>
              <div className={styles["esquerda__div"]}>
                <h4
                  className={styles["esquerda__titulo"]}
                  dangerouslySetInnerHTML={{
                    __html: caso.nome
                      .replace(
                        /\*\*(.*?)\*\*/g,
                        '<strong class="negrito">$1</strong>'
                      )
                      .replace(/\n/g, "<br />"),
                  }}
                ></h4>
                <p
                  className={styles["esquerda__paragrafo"]}
                  dangerouslySetInnerHTML={{
                    __html: caso.apresentacao.replace(/\n/g, "<br />"),
                  }}
                >
                </p>
                <a href={caso.link} target="_blank" rel="noreferrer">
                  <button className={styles["esquerda__botao"]}>
                    Saiba mais
                  </button>
                </a>
              </div>
            </div>
          );
        })}
      </section>
    </>
  );
}
