import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const ScrollToTop = () => {
  const location = useLocation();

  useEffect(() => {
    const onNavigate = () => {
      window.scrollTo(0, 0);
    };

    onNavigate(); // Rola para o topo quando o componente é montado

    const unlisten = () => {
      // Não é necessário fazer nada aqui na v6
    };

    return () => {
      unlisten(); // Remove o listener ao desmontar o componente
    };
  }, [location]);

  return null;
};

export default ScrollToTop;
