import React, { useState } from "react";
import emailjs from "@emailjs/browser";
import styles from "./Newsletter.module.scss";
import Swal from "sweetalert2";
export default function Newsletter() {
  const [emailInput, setEmailInput] = useState("");
  const validaEmail = () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return !emailRegex.test(emailInput);
  };
  const enviarEmail = () => {
    const templateParams = {
      message: `Email: ${emailInput}`,
    };

    emailjs
      .send(
        "service_e233uer",
        "template_cpp11aa",
        templateParams,
        "ErGaHW2if7hSXypcW"
      )
      .then((response) => {
        Swal.fire({
          title: "Sucesso!",
          text: "Cadastro realizado com sucesso!",
          icon: "success",
          confirmButtonColor: "#1A75FF",
        });
        setEmailInput("");
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Erro ao realizar o cadastro!",
          confirmButtonColor: "#1A75FF",
        });
      });
  };

  return (
    <section className={styles.newsletter}>
      <div>
        <h4 className={styles["newsletter__titulo"]}>
          Digite seu endereço de e-mail e comece gratuitamente
        </h4>
        <p className={styles["newsletter__paragrafo"]}>
          Mantenha-se atualizado com as informações mais relevantes, nossas
          novas coleções, novidades e ofertas especiais!
        </p>
        <div className={styles["newsletter__flex"]}>
          <input
            onChange={(event: any) => {
              setEmailInput(event.target.value);
            }}
            className={styles["newsletter__input"]}
            placeholder="Digite seu email"
            type="email"
            required
          />
          <button
            type="submit"
            disabled={emailInput === "" || validaEmail()}
            className={styles["newsletter__botao"]}
            onClick={enviarEmail}
          >
            Inscrever-se
          </button>
        </div>
      </div>
    </section>
  );
}
