import classNames from "classnames";
import styles from "./Rodape.module.scss";
import logo from 'assets/img/logo.png'
export default function Rodape() {
  return (
    <>
<footer className={styles['mt-auto rodape']}>
        <div className={styles['rodape__info']}>
            <div className={classNames({
                [styles['rodape__campo']]: true,
                [styles['logo--div']]: true,
            })}>
                <a href="/" style={{transition: '500ms'}}  ><img src={logo} alt="" className={styles['rodape__logo']}/></a>
                <p className={styles['rodape__ap']}>Adoramos trabalhar com pessoas ambiciosas. Vamos construir algo incrível juntos.</p>
              
            </div>
            <div className={styles["rodape__campo"]}>
                <h3 className={styles["rodape__subtitulo"]}>Navegação</h3>
                <ul className={styles["rodape__lista"]}>
                    <a className={styles["rodape__link"]} href="/">
                        <li className={styles["rodape__lista--item"]}>
                            Início</li>
                    </a>
                    <a className={styles["rodape__link"]} href="/servicos">
                        <li className={styles["rodape__lista--item"]}>
                            Serviços</li>
                    </a>
                    <a className={styles["rodape__link"]} href="/empresa">
                        <li className={styles["rodape__lista--item"]}>
                            Empresa</li>
                    </a>
                    <a className={styles["rodape__link"]} href="/casosdesucesso">
                        <li className={styles["rodape__lista--item"]}>
                            Casos de sucesso</li>
                    </a>
                </ul>
            </div>
            <div className={styles["rodape__campo"]}>
                <h3 className={styles["rodape__subtitulo"]}>Empresa</h3>
                <ul className={styles["rodape__lista"]}>
                    <a className={styles["rodape__link"]} href="/empresa">
                        <li className={styles["rodape__lista--item"]}>
                            Sobre</li>
                    </a>
                    <a className={styles["rodape__link"]} href="/contato">
                        <li className={styles["rodape__lista--item"]}>
                            Contato</li>
                    </a>
                    
                </ul>
            </div>
            <div className={styles["rodape__campo"]}>
                <h3 className={styles["rodape__subtitulo"]}>Contate-nos</h3>
                <ul className={styles["rodape__lista"]}>
                    <a className={styles["rodape__link"]} href="mailto:alcance@alcancetecnologia.com.br">
                        <li className={styles["rodape__lista--item"]}>
                        alcance@alcancetecnologia.com.br</li>
                    </a>
                    <a className={styles["rodape__link"]} href="https://wa.me/554936211469">
                        <li className={styles["rodape__lista--item"]}>
                        +55 (049) 3621 1469</li>
                    </a>
                    <a className={styles["rodape__link"]} href="https://maps.app.goo.gl/uEaazjUDcL6cVDpGA" target="_blank" rel="noreferrer">
                        <li className={styles["rodape__lista--item"]}>
                        São Miguel do Oeste, SC Bairro São Jorge, 1727 Rua Florianópolis</li>
                    </a>
                </ul>
            </div>
        </div>

        <div className={styles["copyright"]}>
            <div className={styles["copyright__paragrafo--div"]}>
                &copy; 2024 Alcance Tecnologia - Todos os direitos reservados
            </div>
           
        </div>           
    </footer>
    </>
  );
}
