import Type from "components/TypeWritter";
import styles from "./Servicos.module.scss";
import imagemFundoServicos from "assets/img/imagem-fundo-servicos.png";
import Tilt from "react-parallax-tilt";
import { ReactComponent as WebIcon } from "assets/svg/web-icon.svg";
import { ReactComponent as SistemasIcon } from "assets/svg/sistemas-icon.svg";
import { ReactComponent as AplicativoIcon } from "assets/svg/apps-icon.svg";
import { ReactComponent as MarketingIcon } from "assets/svg/marketing-icon.svg";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
import { Link } from "react-router-dom";

import gsap from "gsap";
import { useGSAP } from "@gsap/react";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useQuery } from "graphql-hooks";
import { useEffect, useState } from "react";
import classNames from "classnames";

gsap.registerPlugin(useGSAP, ScrollTrigger);

export default function Servicos() {

  const [servicosLoaded, setServicosLoaded] = useState(false);

  const GET_SERVICOS = `
  query {
    allServicos {
      logo {
        url
      }
       nome
      apresentacao
      link
      alinhamentoesquerda
    }
  }`;
  const { loading, error, data } = useQuery(GET_SERVICOS);
  useEffect(() => {
    if (data) {
      setServicosLoaded(true);
    }
  }, [data]);

  useEffect(() => {
    if (servicosLoaded) {
      const elementosEsquerda = document.querySelectorAll(
        `.${styles["esquerda"]}`
      );

      elementosEsquerda.forEach((elemento, index) => {
        gsap.from(elemento, {
          opacity: 0,
          y: 50,
          duration: 1,
          delay: 0.5, // Delay aumenta com base no índice
          scrollTrigger: {
            trigger: elemento,
            start: "top 80%",
            end: "bottom 60%",
            toggleActions: "play none none none",
          },
        });
      });
    }
  }, [servicosLoaded]);
  useGSAP(() => {
    gsap.from(`.${styles["servicos__titulo--div"]}`, {
      opacity: 0,
      y: 50,
      duration: 1,
      delay: 0.5,
    });

    gsap.from(`.${styles["servicos__paragrafo"]}`, {
      opacity: 0,
      y: 50,
      duration: 1,
      delay: 1,
    });



    gsap.from(`.${styles["ferramentas__titulo"]}`, {
      opacity: 0,
      y: 50,
      duration: 1,
      delay: 0.2,
      stagger: 0.5,
      scrollTrigger: {
        trigger: `.${styles["ferramentas"]}`, // O elemento que dispara a animação
        start: "top 80%", // Inicia a animação quando o topo do elemento atinge 80% do viewport
        end: "bottom 60%", // Termina a animação quando o final do elemento atinge 60% do viewport
        toggleActions: "play none none none", // Ação de animação
      },
    });
    gsap.from(`.${styles["produtos__titulo"]}`, {
      opacity: 0,
      y: 50,
      duration: 1,
      delay: 0.2,
      stagger: 0.5,
      scrollTrigger: {
        trigger: `.${styles["produtos"]}`, // O elemento que dispara a animação
        start: "top 80%", // Inicia a animação quando o topo do elemento atinge 80% do viewport
        end: "bottom 60%", // Termina a animação quando o final do elemento atinge 60% do viewport
        toggleActions: "play none none none", // Ação de animação
      },
    });
    gsap.from(`.${styles["ferramentas__lista"]}`, {
      opacity: 0,
      y: 50,
      duration: 1,
      delay: 0.7,
      stagger: 0.5,
      scrollTrigger: {
        trigger: `.${styles["ferramentas"]}`, // O elemento que dispara a animação
        start: "top 80%", // Inicia a animação quando o topo do elemento atinge 80% do viewport
        end: "bottom 60%", // Termina a animação quando o final do elemento atinge 60% do viewport
        toggleActions: "play none none none", // Ação de animação
      },
    });


 
  });

  const responsive = {
    1800: {
      items: 2,
    },
    1200: {
      items: 2,
    },
    800: {
      items: 2,
    },
    500: {
      items: 1,
    },
    0: {
      items: 1,
    },
  };
  const items = [
    <div className={styles["ferramentas__artigo"]}>
      <SistemasIcon></SistemasIcon>
      <h4 className={styles["ferramentas__subtitulo"]}>
        SISTEMAS PERSONALIZADOS
      </h4>
      <p className={styles["ferramentas__paragrafo"]}>
        Desenvolvemos soluções dinâmicas e criativas voltadas para as
        plataformas web e mobile.
      </p>
    </div>,
    <div className={styles["ferramentas__artigo"]}>
      <AplicativoIcon></AplicativoIcon>
      <h4 className={styles["ferramentas__subtitulo"]}>APLICATIVOS</h4>
      <p className={styles["ferramentas__paragrafo"]}>
        Construímos aplicativos modernos e elegantes que atendam as necessidades
        de sua empresa.
      </p>
    </div>,
    <div className={styles["ferramentas__artigo"]}>
      <WebIcon></WebIcon>
      <h4 className={styles["ferramentas__subtitulo"]}>SITES</h4>
      <p className={styles["ferramentas__paragrafo"]}>
        Criamos sites incríveis para divulgar ainda mais a sua empresa e os seus
        produtos.
      </p>
    </div>,
    <div className={styles["ferramentas__artigo"]}>
      <MarketingIcon></MarketingIcon>
      <h4 className={styles["ferramentas__subtitulo"]}>MARKETING</h4>
      <p className={styles["ferramentas__paragrafo"]}>
        Divulgamos estrategicamente seu produto, elevando sua marca em todas as
        mídias digitais.
      </p>
    </div>,
  ];
  return (
    <>
      <section className={styles["servicos"]}>
        <div className={styles["servicos__apresentacao"]}>
          <h2 className={styles["servicos__titulo"]}>
            <div className={styles["servicos__titulo--div"]}>
              <Type
                strings={[
                  "Dê vida aos seus projetos com nossos serviços.",
                  "Elevamos suas expectativas com nossa abordagem.",
                  "Fortaleça sua jornada de sucesso nessa parceria.",
                ]}
              />
            </div>
          </h2>
          <p className={styles["servicos__paragrafo"]}>
            A Alcance Tecnologia oferece uma ampla gama de serviços
            personalizados para atender às suas necessidades específicas.
          </p>
        </div>
        <div className={styles["servicos__apresentacao--imagem"]}>
          <Tilt>
            <img
              src={imagemFundoServicos}
              alt=""
              className={styles["servicos__imagem"]}
            />
          </Tilt>
        </div>
      </section>
      <section className={styles["ferramentas"]}>
        <h3 className={styles["ferramentas__titulo"]}>
          <strong style={{ fontWeight: "bold" }}>O que fazemos</strong>
          <br></br> para o seu negócio
        </h3>
        <div className={styles["ferramentas__lista"]}>
          <AliceCarousel
            mouseTracking
            items={items}
            disableDotsControls={false}
            responsive={responsive}
          />
        </div>
      </section>
      <section className={styles["produtos"]}>
        <h3 className={styles["produtos__titulo"]}>
          <strong style={{ fontWeight: "bold" }}>Navegue por</strong>
          <br></br> nossos produtos
        </h3>
      
      </section>
      <section className={styles["casos"]}>
        {data?.allServicos.map((caso: any, index: any) => {
          return (
            <div
              key={index}
              className={classNames({
                [styles["esquerda"]]: true,
                [styles["direita"]]: caso.alinhamentoesquerda ? false : true,
              })}
            >
              <div className={styles["esquerda__div"]}>
                <img
                  className={styles["esquerda__imagem"]}
                  src={caso.logo.url}
                  alt="eBrigada"
                />
              </div>
              <div className={styles["esquerda__div"]}>
                <h4
                  className={styles["esquerda__titulo"]}
                  dangerouslySetInnerHTML={{
                    __html: caso.nome
                      .replace(
                        /\*\*(.*?)\*\*/g,
                        '<strong class="negrito">$1</strong>'
                      )
                      .replace(/\n/g, "<br />"),
                  }}
                ></h4>
                <p
                  className={styles["esquerda__paragrafo"]}
                  dangerouslySetInnerHTML={{
                    __html: caso.apresentacao.replace(/\n/g, "<br />"),
                  }}
                >
                </p>
                <a href={caso.link} target="_blank" rel="noreferrer">
                  <button className={styles["esquerda__botao"]}>
                    Saiba mais
                  </button>
                </a>
              </div>
            </div>
          );
        })}
      </section>
      <section className={styles["entrecontato"]}>
        <div className={styles["entrecontato__div"]}>
          <h4 className={styles["entrecontato__titulo"]}>
            <strong style={{ fontWeight: "bold" }}>Interessado?</strong>{" "}
            <br></br>entre em contato conosco
          </h4>
        </div>
        <div className={styles["entrecontato__div"]}>
          <Link to={"/contato"}>
            <button className={styles["entrecontato__botao"]}>
              Entrar em contato
            </button>
          </Link>
        </div>
      </section>
    </>
  );
}
